import React from "react";
import Swiper from "react-id-swiper";
import { Image } from "react-bootstrap";
import LazyLoad from "react-lazyload";
var data = [];
const Pagination = (props) => {
	data = props.events;

	const params = {
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
		},
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
		},
	};

	const renderBanner = (img) => {
		return {
			backgroundImage: "url(" + img + ")",
		};
	};
	return (
		<Swiper {...params} shouldSwiperUpdate>
			{data != null ? (
				Object.keys(data).map((id) =>
					data[id].featured_desktop_banner != null
						? Object.keys(data[id].featured_desktop_banner).map((sub) => (
								<div>
									<figure className="desk-pic" style={renderBanner(data[id].featured_desktop_banner[sub])}>
										<a href={data[id].external_link} className="ctm-block">
											{data[id].featured_mobile_banner != undefined ? (
												<LazyLoad height={200}>
													<Image src={data[id].featured_mobile_banner[sub]} fluid className="mob-pic" />
												</LazyLoad>
											) : null}
										</a>
									</figure>
								</div>
						  ))
						: null
				)
			) : (
				<div></div>
			)}
		</Swiper>
	);
};

export default Pagination;
