// import React, { useEffect } from "react";
// import event from "../../images/stanndup-poster.jpg";
// import { Container, Row, Col, Image } from "react-bootstrap";
// import { Scrollbars } from "react-custom-scrollbars";
// import { withTranslation } from "react-i18next";
// import LazyLoad from "react-lazyload";
// const Event = (props) => {
// 	let eventsData = props.events;
import React, { Component, useEffect } from 'react'
import event from '../../images/bhadipa-live-events.jpeg';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import LazyLoad from "react-lazyload";
import { withTranslation, Trans } from 'react-i18next';
import {
    Link
} from "react-router-dom";
const Event = props => {
    let eventsData = props.events;
    let lang = props.lang;
    console.log("lang of event ///////" , lang)
    // console.log(eventsData)
    const { t, i18n } = props;
    // const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    //     "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    // ];

	const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

	useEffect(() => {});
	return (
		<Container>
			<Row>
				<Col lg={{ size: 9, offset: 3 }} className="base-box">
					<div className="pic-box ">
						<h2 className="title wow fadeIn">{props.t("events.events", { framework: "Events" })}</h2>
						<figure className="event-pic ctm-hid wow fadeInLeft" data-wow-delay="0.1s">
							<LazyLoad height={200}>
								<Image src={event} fluid />
							</LazyLoad>
						</figure>
					</div>
					<Scrollbars style={{ height: 440 }}>
						{eventsData !== 0 ? (
							Object.keys(eventsData).map((id) => (
								<Col lg={12} ClassName="wow fadeInUp" data-wow-delay="0.2s">
									<Row id={id} className={id === 0 ? "event-list no-top" : "event-list"}>
										<Col md={8} xs={8} className="venue">
											<h4>{eventsData[id].title}</h4>
											<div className="details">
												<p>{eventsData[id].city}</p>
												<p>,{eventsData[id].state}</p>
												<p className="time">
													{eventsData[id].start_time} {props.t("events.from", { framework: "onwards" })}{" "}
												</p>
											</div>
										</Col>
										<Col md={4} xs={4} className="date">
											<div className="month">{monthNames[new Date(eventsData[id].event_date).getMonth()]}</div>
											<div className="num">{new Date(eventsData[id].event_date).getDate()}</div>
											<div className="year">{new Date(eventsData[id].event_date).getFullYear()}</div>
											{eventsData[id].external_link !== "" ? (
												<a href={eventsData[id].external_link} target="_blank" rel="noopener noreferrer" className="cm-btn">
													{props.t("events.book", { framework: "Book now" })}{" "}
												</a>
											) : (
												<h6>More Coming Soon</h6>
											)}
										</Col>
									</Row>
								</Col>
							))
						) : (
							<Col lg={12} ClassName="wow fadeInUp" data-wow-delay="0.2s">
								<h1>More Coming Soon</h1>
							</Col>
						)}
						{eventsData.length === 1 || eventsData.length === 2 ? (
							<Col lg={12} ClassName="wow fadeInUp" data-wow-delay="0.2s">
								<h1 class="come-back-txt">More Coming Soon</h1>
							</Col>
						) : null}
					</Scrollbars>
					<Row className="event-list contact wow fadeInUp" data-wow-delay="0.4s">
						<Col md={8} xs={8} className="venue">
							<div className="details typ-event-book">
								<p>{props.t("events.see", { framework: "Don't see an event for you? Contact our team to organise your own BhaDiPa event" })}</p>
							</div>
						</Col>
						<Col md={4} xs={4} className="date typ-book-event">
							<a href="/contact" className="cm-btn">
								{props.t("events.book_evt", { framework: "Book an event" })}
							</a>
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	);
};
export default withTranslation("common")(Event);
