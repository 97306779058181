import React, { Component } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";

// import NetworkHelper from "../../helpers/NetworkHelper";
// import Constants from "../module/constants";

class Logiinform extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			password: "",
			validemail: "",
			validpassword: "",
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	UNSAFE_componentWillMount() {}

	handleSubmit(event) {
		// (this.state.validemail == "true" && this.state.validpassword == "true") ?
		//   alert("Answer" + this.state.email)

		//   : alert("please enter")

		// var networkHelper = new NetworkHelper();
		// networkHelper.setUrl(Constants.get_home_page_data);
		// networkHelper.setMethod("POST");
		// networkHelper.setData(JSON.stringify({
		//   'lang_code': lang
		// }))
		// networkHelper.execute((response) => {
		//   // console.log("Home Page Data >>>", response.data)
		//   this.setState({

		//   })
		// }, function (errorMsg, StatusCode) {
		//   console.log(errorMsg)

		// }, function () {
		// });

		event.preventDefault();
	}

	handleChange(e) {
		// *** validation for Firstname,Lastname,Contact,Email and Password ***
		let pattern = "";
		switch (e.target.name) {
			case "email": // allows Email format
				pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				break;

			case "password": // allows min 8 character, special character, a uppercase and lowercase letter
				pattern = /^.{8,63}$$/;
				break;

			default:
				break;
		}

		if (pattern.test(e.target.value)) {
			this.setState({
				[e.target.name]: e.target.value,
				["valid" + e.target.name]: "true",
			});
		} else {
			this.setState({
				[e.target.name]: e.target.value,
				["valid" + e.target.name]: "false",
			});
		}
	}
	render() {
		return (
			<div className="bs-form login">
				<Form onSubmit={this.handleSubmit}>
					<Row>
						{/* <Col md={12}>
                <Form.Control placeholder="Name" />
              </Col> */}
						<Col md={12}>
							<Form.Control type="email" placeholder="Email Id" isValid={this.state.validemail === "true"} isInvalid={this.state.validemail === "false"} name="email" autocomplete="off" onChange={this.handleChange} />
						</Col>
						<Col md={12}>
							<Form.Control type="password" placeholder="Password" isValid={this.state.validpassword === "true"} isInvalid={this.state.validpassword === "false"} name="password" autocomplete="off" onChange={this.handleChange} />
						</Col>

						<Button type="submit" className="ctm-btn">
							Sign In
						</Button>
					</Row>
				</Form>
			</div>
		);
	}
}
export default Logiinform;
