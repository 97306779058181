import React, { Component } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import Constants from "../module/constants";
import NetworkHelper from "../../helpers/NetworkHelper";
class Collaborate extends Component {
	constructor() {
		super();
		this.state = {
			fields: {},
			errors: {},
		};
		this.handleChange = this.handleChange.bind(this);
		this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
	}

	submitForm = () => {
		console.log("Submit");
		// e.preventDefault();
		var networkHelper = new NetworkHelper();
		networkHelper.setUrl(Constants.collab);
		networkHelper.setMethod("POST");
		networkHelper.setData(
			JSON.stringify({
				name: this.state.fields.name,
				email: this.state.fields.email,
				mobile_no: this.state.fields.mobile_no,
				channel_link: this.state.fields.channel_link,
				proposal: this.state.fields.propsal,
			})
		);
		console.log("state Data >>>", this.name);
		networkHelper.execute(
			(response) => {
				console.log("response Data >>>", response.data);
				this.setState({});
			},
			function (errorMsg, StatusCode) {
				console.log(errorMsg);
			},
			function () {}
		);
	};

	UNSAFE_componentWillMount() {
		window.scrollTo(0, 0);
		// this.submitForm();
	}

	handleChange(e) {
		let fields = this.state.fields;
		fields[e.target.name] = e.target.value;
		this.setState({
			fields,
		});
	}

	submituserRegistrationForm(e) {
		e.preventDefault();
		if (this.validateForm()) {
			let fields = {};
			fields["name"] = "";
			fields["email"] = "";
			fields["mobile_no"] = "";
			fields["channel_link"] = "";
			fields["propsal"] = "";
			this.setState({ fields: fields });
			this.submitForm();
			alert("Form submitted");
		}
	}

	validateForm() {
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;

		if (!fields["name"]) {
			formIsValid = false;
			errors["name"] = "false";
		}

		if (typeof fields["name"] !== "undefined") {
			//Full name
			if (!fields["name"].match(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/)) {
				formIsValid = false;
				errors["name"] = "false";
			}
		}

		if (!fields["email"]) {
			formIsValid = false;
			errors["email"] = "false";
		}

		if (typeof fields["email"] !== "undefined") {
			//regular expression for email validation
			var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
			if (!pattern.test(fields["email"])) {
				formIsValid = false;
				errors["email"] = "false";
			}
		}

		if (!fields["mobile_no"]) {
			formIsValid = false;
			errors["mobile_no"] = "false";
		}

		if (typeof fields["mobile_no"] !== "undefined") {
			if (!fields["mobile_no"].match(/^[0-9]{10}$/)) {
				formIsValid = false;
				errors["mobile_no"] = "false";
			}
		}

		if (!fields["channel_link"]) {
			formIsValid = false;
			errors["channel_link"] = "false";
		}

		if (typeof fields["channel_link"] !== "undefined") {
			if (!fields["channel_link"].match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/)) {
				formIsValid = false;
				errors["channel_link"] = "false";
			}
		}

		if (!fields["propsal"]) {
			formIsValid = false;
			errors["propsal"] = "false";
		}

		if (typeof fields["propsal"] !== "undefined") {
			//not null
			if (!fields["propsal"].match(/^$|\s*/)) {
				formIsValid = false;
				errors["propsal"] = "false";
			}
		}

		this.setState({
			errors: errors,
		});
		return formIsValid;
	}

	render() {
		// console.log("Statwe", this.state.fields.name)
		return (
			<div className="bs-form typ-contact-forms">
				<Form onSubmit={this.submituserRegistrationForm}>
					<Row>
						<Col md={12} className="m-b-40">
							<Form.Control className="inpt-p-top-0" placeholder="Name" type="text" name="name" value={this.state.fields.name} onChange={this.handleChange} isValid={this.state.errors.name === "true"} isInvalid={this.state.errors.name === "false"} autocom />
							{this.state.errors.name === "false" ? <label>Enter the name</label> : ""}
						</Col>
						<Col md={12} className="m-b-40">
							<Form.Control type="email" placeholder="Email Id" name="email" value={this.state.fields.email} onChange={this.handleChange} isValid={this.state.errors.email === "true"} isInvalid={this.state.errors.email === "false"} />
							{this.state.errors.email === "false" ? <label>Enter valid email</label> : ""}
						</Col>
						<Col md={12} className="m-b-40">
							<Form.Control placeholder="Mobile No" type="text" name="mobile_no" value={this.state.fields.mobile_no} onChange={this.handleChange} isValid={this.state.errors.mobile_no === "true"} isInvalid={this.state.errors.mobile_no === "false"} />
							{this.state.errors.mobile_no === "false" ? <label>Enter valid number</label> : ""}
						</Col>
						<Col md={12} className="m-b-40">
							<Form.Control type="text" placeholder="Share the link:" name="channel_link" value={this.state.fields.channel_link} onChange={this.handleChange} isValid={this.state.errors.channel_link === "true"} isInvalid={this.state.errors.channel_link === "false"} />
							{this.state.errors.channel_link === "false" ? <label>Enter valid link</label> : ""}
						</Col>
						<Col md={12}>
							<Form.Control as="textarea" rows="1" placeholder="Proposal" className="last" value={this.state.fields.proposal} name="propsal" onChange={this.handleChange} isValid={this.state.errors.propsal === "true"} isInvalid={this.state.errors.propsal === "false"} />
							{this.state.errors.propsal === "false" ? <label>Enter valid propsal</label> : ""}
						</Col>
						<Button type="submit" className="ctm-btn">
							<i class="icon icon-plane"></i>
						</Button>
					</Row>
				</Form>
			</div>
		);
	}
}
export default Collaborate;
