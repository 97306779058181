import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Container, Nav } from "react-bootstrap";


class Links extends Component{
render()
    {
        return(
            <Container>
                <section className="lyt-white">
                <Nav className="ctm-links">
            <Nav.Item>
              <Nav.Link as={Link} to="/">Home</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/channel">Channel</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/events">Events</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/eventsformat">Events Format</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/comedians">Comedians</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/talent">Talent</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/about">About</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/contact">Get In Touch</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/media">Media</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/terms">Terms of Use</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/privacy">Privacy Policy</Nav.Link>
            </Nav.Item>
          </Nav>
                </section>
        
          </Container>
        )
    }
}
export default Links;
