import React from 'react'
import { Link } from 'react-router-dom'

import youtube from '../../images/youtube.png'
import facebook from '../../images/facebook.png'
import mxplayer from '../../images/mx-player.png'
import { Container, Row, Col, Image, Carousel } from 'react-bootstrap'
import NetworkHelper from '../../helpers/NetworkHelper'
import Constants from '../module/constants'
import WOW from 'wow.js'
import { withTranslation } from 'react-i18next'
import SwiperComedian from '../module/Swiper-comedian'
import Swiperrecentchannel from '../module/Swiper-recent-channel'
import { changeTabFilter } from '../module/Notify'
import LazyLoad from 'react-lazyload'
import SwiperTalent from '../module/SwiperTalent'
//  import SwiperTalent from "../module/SwiperTalent";
import { Navigation, Pagination, Scrollbar } from 'swiper'
import swiper from 'swiper'
import SwiperComponent from '../module/SwiperComponent'
import SwiperCreator from '../module/SwiperCreactor'
import SwiperInfluencer from '../module/SwiperInfluencer'
import Instagram from '../../common/Instagram'
//  import SwiperTalent from "../module/SwiperTalent";
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/swiper-bundle.min.css'
// import 'swiper/swiper.min.css'
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';
class Talent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ComediansData: [],
      WritersData: [],
      InfluencersData: [],
      comed: [],
        writ : [],
       influ : [],
      lang: '',
    }
    this.changeTab = this.changeTab.bind(this)
  }

  changeTab = (value) => {
    changeTabFilter(value)
    window.localStorage.setItem('tab', value)
  }
changeTab = (value) => {
  changeTabFilter(value);
  window.localStorage.setItem('tab', value)
}
  //Change the lang using constructor
  changeLang = (val) => {
    const { t,i18n } = this.props
    // console.log("t>>>>")
    i18n.changeLanguage(val)
    this.getComediansData(val)
    this.getWritersData(val)
    this.getInfluencersData(val)
  }
  componentDidMount() {
    const wow = new WOW().init();
    if (!window.localStorage.getItem('language') != null) {
        const getLang = window.localStorage.getItem('language') || "en";
        // this.getComediansData(getLang);
        this.changeLang(getLang);
        this.setState({
            lang: getLang
        })
    }
     this.changeTab = this.changeTab.bind(this);
      this.getLanguage();
}
//   getLanguage() {
//     window.addEventListener('filterLanguage', (e) => {
//         this.setState({
//             lang: e.detail,
//         })
//         // console.log("PROPS", this.props)
//         const { t, i18n } = this.props;
//         i18n.changeLanguage(e.detail);
//         this.fetchChannelPageData(e.detail);
//     })
// }
  getLanguage() {
    window.addEventListener('filterLanguage', (e) => {
      this.setState({
        lang: e.detail,
    })
    // co
      const { i18n } = this.props
      i18n.changeLanguage(e.detail)
      this.getComediansData(e.detail)
      this.getWritersData(e.detail)
      this.getInfluencersData(e.detail)
    })
  }

  getComediansData(lang) {
    var networkHelper = new NetworkHelper()
    networkHelper.setUrl(Constants.get_comedians_page_data)

    networkHelper.setMethod('POST')
    networkHelper.setData(
      JSON.stringify({
        lang_code: lang,
      }),
    )
    networkHelper.execute(
      (response) => {
        this.setState({
          // ComediansData: (response.data.comedians).reverse(),
         ComediansData: response.data,
            // comed: (response.data.comedians).reverse()
        })
        console.log('response', response)
      },
      function (errorMsg, StatusCode) {
        console.log(errorMsg)
      },
      function () {},
    )
  }
  getWritersData(lang) {
    var networkHelper = new NetworkHelper()
    networkHelper.setUrl(Constants.get_writers_page_data)

    networkHelper.setMethod('POST')
    networkHelper.setData(
      JSON.stringify({
        lang_code: lang,
      }),
    )
    networkHelper.execute(
      (response) => {
        this.setState({
          WritersData: response.data,
          //  writ : response.data.writers.reverse()
        })
        console.log('response', response)
      },
      function (errorMsg, StatusCode) {
        console.log(errorMsg)
      },
      function () {},
    )
  }

  getInfluencersData(lang) {
    var networkHelper = new NetworkHelper()
    networkHelper.setUrl(Constants.get_influencer_page_data)

    networkHelper.setMethod('POST')
    networkHelper.setData(
      JSON.stringify({
        lang_code: lang,
      }),
    )
    var talentlang = this.state.lang
    networkHelper.execute(
      (response) => {
        this.setState({
          InfluencersData: response.data,
            // influ : response.data.influencers.reverse()
        })
        console.log('response', response)
      },
      function (errorMsg, StatusCode) {
        console.log(errorMsg)
      },
      function () {},
    )
  }
 
  render() {
    const { ComediansData, WritersData, InfluencersData , lang} = this.state
    console.log(WritersData, 'WritersData')
    console.log(ComediansData, 'ComediansData')
    console.log(InfluencersData, 'InfluencersData')
    //  console.log(comed, 'comed')
    //  console.log(influ, 'influ')
      // console.log(comed, 'writer222')

    return (
      <div>
        <section className="lyt-base">
          <Container className="content">
            <SwiperComponent
              comedians={ComediansData.comedians}
              lang={this.state.lang}
             
              swip="right"
              type="1"
           
            />
            <Row>
              <Col
                lmd={12}
                lg={6}
                className="bhadipa-banner ctm-col wow fadeIn"
              ></Col>
              <Col lg={10}>
                <div className="bx-white">
                  <h2 className="title wow fadeInUp" data-wow-delay="0.1s">
                    {''}
                    {console.log("this.state.lang talent>>>>>>>>>>>" , this.state.lang)}
                    {/* {this.props.t('comedians.title', {
                      framework: 'Its all about our Talent',
                    })} */}
                    {this.state.lang == "en" ? ("Its all about our Talent"): ("आमच्या सर्व प्रतिभेबद्दल")}
                  </h2>
                  <p className="wow fadeInUp" data-wow-delay="0.2s">
                    {this.props.t('comedians.description', {
                      framework:
                        'BhaDiPa manages a variety of talent from across Maharashtra. From Digital Creators, Stand-Up Comedians, Actors, Writers and Directors, BhaDiPa manages the new wave of Marathi culture from an early stage in their careers. Contact us to see which talent most suits for your needs.',
                    })}
                  </p>
                  <Link
                    to="/contact"
                    onClick={() => this.changeTab('collaborate')}
                    className="cm-btn yellow bg-shadow wow fadeInUp"
                    data-wow-delay="0.3s"
                  >
               
               {this.state.lang == "en" ? ("BOOK A TALENT") : ("प्रतिभा बुक करा")}
                    {/* {this.props.t('comedians.book_btn', {
                      framework: 'Book a comic',
                    })} */}
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
          
        { ComediansData.comedians != null ?    
         ( <section className="lyt-swiper base recent">
            <Container>
              <Row>
                <Col md={12} className="wow fadeInUp">
                  <h2 className='newh2'> {this.state.lang == 'en' ? ("COMEDIANS") : ("हास्य अभिनेता")} </h2>
                </Col>
                <Col md={12} className="wow fadeInUp" data-wow-delay="0.2s">
                  {console.log('comedians data >>', ComediansData.comedians)}
                  {/* {console.log('111>>', ComediansData.comedians.reverse())} */}
              

                  <SwiperTalent comedians={ComediansData} lang={this.state.lang} swip="right" />
                  <Col className="channel-desc">
                    {/* <div className="ctm-left typ-v-center"></div>
                    <div className="ctm-right typ-v-center"></div> */}
                  </Col>
                </Col>
              </Row>
            </Container>
          </section>
         ):null
        }
        {/* <SwiperTalent comedians={comed} lang={this.state.lang} swip="right" /> */}
        {
          WritersData.writers != null ?
          (<section className="lyt-swiper2 base recent">
            <Container>
              <Row>
                <Col md={12} className="channel-desc wow fadeInUp">
                  <h2>
                  
                  {this.state.lang == 'en' ?("WRITERS AND DIRECTORS") : ("लेखक आणि दिग्दर्शक") } </h2>
                </Col>
                <Col md={12} className="wow fadeInUp" data-wow-delay="0.2s">
                  {console.log('wriyters data >>', WritersData)}
                  {/* {console.log('WritersData data reverse>>', WritersData.writers.reverse())} */}
                  <SwiperCreator writers={WritersData}  lang={this.state.lang} swip="right"/>
                  <Col className="channel-desc">
                  {/* <div className="ctm-left"></div>
                  <div className="ctm-right"></div> */}
                </Col>
                </Col>
              
              </Row>
            </Container>
          </section>) : null
        }
          {
            InfluencersData.influencers != null ?

          (<section class="lyt-swiper3 theme">
            <Container>
              <Row>
                <Col md={12} className="channel-desc wow fadeInUp">
                  <h2>
                  {this.state.lang == 'en' ? ("CREATORS AND ACTORS") : ("निर्माते आणि अभिनेते")}
                  </h2>
                </Col>
                <Col md={12} className="wow fadeInUp" data-wow-delay="0.2s">
                  {console.log('wriyters data >>', InfluencersData) }
                  {/* {console.log('InfluencersData data reverse>>', InfluencersData.influencers.reverse())} */}
                  <SwiperInfluencer influencers={InfluencersData} lang={this.state.lang} swip="right" />
                </Col>

                <Col className="channel-desc">
                  {/* <div className="ctm-left1"></div>
                  <div className="ctm-right1"></div> */}
                </Col>
              </Row>
            </Container>
          </section>) : null
          }
        </section>

        <section>
          <Container fluid={true} className="lyt-social typ-mt30">
            <Row>
              <Col md={12} lg={12} className="bg-base">
                <h2
                  className="title wow fadeInUp footer-text"
                  data-wow-delay="0.1s"
                >
                  {this.props.t('social.watch', { framework: 'Watch us on' })}
                </h2>
                <a
                  className="fluid-icon wow fadeInUp"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.youtube.com/channel/UCUw8vQF-X7CJqdVpxBYcavQ"
                  data-wow-delay="0.2s"
                >
                  <figure>
                    <LazyLoad height={200}>
                      <Image src={youtube} fluid />
                    </LazyLoad>
                  </figure>
                </a>
                <a
                  className="fluid-icon wow fadeInUp"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/BhaDiPa/"
                  data-wow-delay="0.3s"
                >
                  <figure>
                    <LazyLoad height={200}>
                      <Image src={facebook} fluid />
                    </LazyLoad>
                  </figure>
                </a>
                <Instagram />
                <a
                  href="/contact"
                  className="cm-btn bg-shadow wow fadeInUp"
                  data-wow-delay="0.5s"
                >
                  {this.props.t('social.get', { framework: 'Get In Touch' })}
                  {console.log('this.prop.t', this.props.t)}
                </a>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    )
  }
}

export default withTranslation('common')(Talent)
