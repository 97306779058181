//  //const baseUrl = "https://bhadipa.com/beta/admin/public";
//  const baseUrl = "http://127.0.0.1:8000";

      const baseUrl = "https://bhadipa.com/beta/admin/public";
    //const baseUrl = "https://creativewebo.in/projects/bhadipa/bhadipa/public"
  // const baseUrl = "http://127.0.0.1:8000";

const Constants = {
	login: baseUrl + "/api/login",
	register: baseUrl + "/api/register",
	get_home_page_data: baseUrl + "/api/get_home_page_data",
	collab: baseUrl + "/api/collab",
	get_events_page_data: baseUrl + "/api/get_events_page_data",
	get_comedians_page_data: baseUrl + "/api/get_comedians_page_data",
	get_writers_page_data: baseUrl + "/api/get_writers_page_data",
	get_influencer_page_data: baseUrl + "/api/get_influencer_page_data",
	advertise: baseUrl + "/api/advertise",
	career: baseUrl + "/api/career",
	contactus: baseUrl + "/api/contactus",
	get_media_page_data: baseUrl + "/api/get_media_page_data",
	get_channel_page_data: baseUrl + "/api/get_channel_page_data",
	get_series_page_data: baseUrl + "/api/get_series_page_data",
	get_events_format_page_data: baseUrl + "/api/get_events_format_page_data",
	get_navbar_links_data: baseUrl + "/api/get_navbar_links_data",
	get_search_page_data: baseUrl + "/api/get_search_page_data",
	get_webseries_page_data: baseUrl + "/api/get_webseries_page_data",
};

export default Constants;
