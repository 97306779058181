import React, { useState, useEffect, useCallback } from "react";
import Swiper from "react-id-swiper";
import { Image, Row, Col, Button, Modal } from "react-bootstrap";
import VideoPlayer from "../layouts/Video-player";
import { changeVideoId } from "../module/Notify";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import videoPlaceHolder from "../../images/video-placeholder.png";
import {Shimmer } from 'react-shimmer'
const Gallery = (props) => {
	var videos = props.videoData;
	var arr = [];
	var lang = props.lang;
	if (videos !== null) {
		Object.keys(videos).map((id) => {
			arr.push({
				id: id,
				yt_id: videos[id].id,
				img: videos[id].image.en,
				url: videos[id].yt_id,
				title: videos[id].title,
				description: videos[id].description,
			});
		});
	}

	if (props.autoplay !== null) {
		changeVideoId(arr.findIndex((obj) => obj.yt_id === props.autoplay));
	}
	const [currentIndex, updateCurrentIndex] = useState(0);
	const [popup, setPopup] = useState(false);
	const [popupHeading, setPopupHeading] = useState(null);
	const [popupDescription, setPopupDescription] = useState(null);

	const [divShow, Setshowdiv] = useState(true);
	var [timer, setTimer] = useState(0);
	var [swiperbox, setSwiperbox] = useState(0);
	var [closeBanner, setCloseBanner] = useState(false);
	const [gallerySwiper, getGallerySwiper] = useState(null);
	const [thumbnailSwiper, getThumbnailSwiper] = useState(null);
	const gallerySwiperParams = {
		getSwiper: getGallerySwiper,
		effect: "fade",
		fadeEffect: {},
		centeredSlides: true,
		slidesPerView: "1",
		loop: false,
	};
	var imgClasses = [""];
	var carouselClasses = ["info"];
	var mainSliderClasses = ["swiper-container-box"];
	const thumbnailSwiperParams = {
		getSwiper: getThumbnailSwiper,
		effect: "coverflow",
		centeredSlides: true,
		direction: "horizontal",
		slidesPerView: "3",
		loop: false,
		// rebuildOnUpdate: true,
		followFinger: true,
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		breakpoints: {
			1200: {
				slidesPerView: 3,
				spaceBetween: 30,
			},
			1024: {
				slidesPerView: 2,
				spaceBetween: 0,
			},
			768: {
				slidesPerView: 2,
				spaceBetween: 20,
			},
			640: {
				slidesPerView: 2,
				spaceBetween: 20,
			},
			320: {
				slidesPerView: 1,
				spaceBetween: 10,
			},
		},
	};

	const handlePopup = (title, description) => {
		if (title !== undefined) {
			setPopup(true);
			setPopupDescription(description);
			setPopupHeading(title);
		} else {
			setPopup(false);
		}
	};

	const updateIndex = useCallback(() => {
		updateCurrentIndex(thumbnailSwiper.realIndex);
		changeVideoId(thumbnailSwiper.realIndex);
		thumbnailSwiper.slideTo(thumbnailSwiper.realIndex, 0);
	}, [thumbnailSwiper]);

	useEffect(() => {
		if (thumbnailSwiper !== null && thumbnailSwiper.controller) {
			thumbnailSwiper.on("slideChange", updateIndex);
			thumbnailSwiper.slideTo(currentIndex, 0);
		}
	}, [gallerySwiper, thumbnailSwiper]);

	var animateBox = props.hideObject;
	if (animateBox == true) {
		if (closeBanner == true) {
			setCloseBanner(false);
			Setshowdiv(true);
		}
		var imgClassesElement = document.getElementById("imgClasses");
		var carouselClassesElement = document.getElementById("carouselClasses");
		if (imgClassesElement !== null && carouselClassesElement !== null) {
			imgClassesElement.removeAttribute("style");
			carouselClassesElement.removeAttribute("style");
		}
		imgClasses.push("wow slideOutLeft animated");
		carouselClasses.push("wow slideOutRight animated");
		mainSliderClasses.push("wow slideOutRight animated");
		setTimeout(
			function () {
				Setshowdiv(false);
			}.bind(this),
			500
		);
	}

	const closeBannerChild = () => {
		props.closeBanner();
		setCloseBanner(true);
		Setshowdiv(true);
		setTimeout(() => {
			setCloseBanner(false);
		}, 500);
	};
	return (
		<div className="bg-swiper">
			<Modal
				show={popup}
				onHide={() => {
					handlePopup();
				}}
				className="bs-popup"
			>
				<Modal.Header closeButton>
					<Modal.Title>{popupHeading}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div
						dangerouslySetInnerHTML={{
							__html: popupDescription,
						}}
					></div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						className="model-btn"
						variant="secondary"
						onClick={() => {
							handlePopup();
						}}
					>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
			{videos !== "" && arr !== "" && closeBanner == false ? <VideoPlayer closeBanner={() => closeBannerChild()} videos={videos != null ? arr : null} autoplay={props.autoplay != null ? props.autoplay : null} /> : null}
			{divShow === true ? (
				<section className="series-swiper">
					{videos !== null ? (
						<div key={currentIndex}>
							<Row className="details series-detl">
								<Col lg={6} md={6} id="imgClasses" className={closeBanner == false ? imgClasses.join(" ") : "wow slideInLeft animated"}>
									<figure>
										<LazyLoad height={200}>
											<Image fallback={<Image src={videoPlaceHolder} height="100" width="100%" fluid />} NativeImgProps={{ height: 100, width: "100%", className: "img-fluid" }} src={lang === "en" ? videos[currentIndex].image.en : videos[currentIndex].image.ma} />
										</LazyLoad>
									</figure>
								</Col>
								<Col lg={6} md={6} id="carouselClasses" className={closeBanner == false ? carouselClasses.join(" ") : "info wow slideInRight animated"}>
									<Link to="/" className="back-link">
										<i className="icon icon-right-arrow-big"></i>Back
									</Link>
									<h2>{props.pathname == "not_tag" ? props.playlistData.title : props.pathname}</h2>
									{props.pathname === "not_tag"
										? Object.keys(videos[currentIndex].tags).map((cid) => (
												<Link key={cid} to={`/tag/${videos[currentIndex].tags[cid]}`} className="tag-btn">
													{}
													{videos[currentIndex].tags[cid]}
												</Link>
										  ))
										: null}

									<h3>{videos[currentIndex].title}</h3>
									{videos[currentIndex].description !== null ? (
										videos[currentIndex].description.length > 120 ? (
											<p>
												<div
													dangerouslySetInnerHTML={{
														__html: `${videos[currentIndex].description}`,
													}}
												></div>
												<Button
													className="read-btn"
													onClick={() => {
														handlePopup(videos[currentIndex].title, videos[currentIndex].description);
													}}
												>
													Read More
												</Button>
											</p>
										) : (
											<div>
												<p>{videos[currentIndex].description}</p>
											</div>
										)
									) : (
										console.log("no data")
									)}
								</Col>
							</Row>
						</div>
					) : null}
					{/* </Swiper> */}
					<div className={mainSliderClasses.join(" ")}>
						<Swiper {...thumbnailSwiperParams} swipeHandler shouldSwiperUpdate>
							{videos !== null
								? Object.keys(videos).map((id) => (
										<div key={id}>
											<figure className="series-pic">
												<LazyLoad height={200}>
													<Image fallback={<Image src={videoPlaceHolder} height="200" fluid />} NativeImgProps={{ height: 200, className: "img-fluid" }} src={lang === "en" ? (videos[id].image.en !== undefined ? videos[id].image.en : "https://bhadipa.com/beta/admin/public/api/get_default_image/video") : videos[id].image.ma !== undefined ? videos[id].image.ma : "https://bhadipa.com/beta/admin/public/api/get_default_image/video"} />
												</LazyLoad>
												<figcaption className="video-name">{<p>{videos[id].title}</p>}</figcaption>
											</figure>
										</div>
								  ))
								: ""}
						</Swiper>
					</div>
				</section>
			) : null}
		</div>
	);
};

export default Gallery;
