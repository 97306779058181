import React from 'react';
import VideoListItem from './video_list_item';
const VideoList = (props) => {
    // console.log("VideoListItem",props.videos)
    const videoItems = props.videos.map((video) => {
        // console.log("Items", video)
        return (
            <VideoListItem
                onUserSelected={props.onVideoSelect}
                key={video.yt_id}
                video={video} 
                className={"no-padding"}/>
        );
    });

    return (

        <ul className="list-group">
            {videoItems}
        </ul>
    );
};

export default VideoList;