import React from "react";
// import staticbanner from '../../images/merch.jpg';
import staticbanner from "../../images/merchendise-mobile-banner.jpg";
import youtube from "../../images/youtube.png";
import facebook from "../../images/facebook.png";
import mxplayer from "../../images/mx-player.png";
import instagram from "../../images/instagram-logo.png";
import Swiper from "./../module/Swiper";

import Swiperweb from "./../module/Swiper-web";
import Event from "./../module/Event";
import { Container, Row, Col, Image as ImageTag } from "react-bootstrap";
import SwiperComponent from "../module/SwiperComponent";
import NetworkHelper from "../../helpers/NetworkHelper";
import Constants from "../module/constants";

import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import loaderImg from "../../images/loader.gif";
import videoPlaceHolder from "../../images/video-placeholder.png";
import { Image } from "react-shimmer";
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      HomePageData: [],
      channel_section: [],
      home_top_section: [],
      events_section: [],
      lang: "en",
      loading: true,
      count: 0,
      loaded: false,
    };
  }

  onImgLoaded = () => {
    this.setState({
      loaded: true,
    });
  };

  //Change the lang using constructor
  changeLang = (val) => {
    const { i18n } = this.props;
    i18n.changeLanguage(val);
    this.fetchHomePageData(val);
  };

  //Loader function
  imgLoader = () => {
    if (this.state.count >= 3) {
      this.setState({
        loading: false,
      });
    } else {
      this.setState({
        count: this.state.count + 1, //counts 'onLoad' in Img tags and update
      });
    }
  };

  // Event emiter for language changed
  getLanguage() {
    window.addEventListener("filterLanguage", (e) => {
      const { i18n } = this.props;
      if (e.detail != null) {
        this.setState({
          lang: e.detail,
        });
      }
      i18n.changeLanguage(e.detail);
      this.fetchHomePageData(e.detail);
    });
  }

  UNSAFE_componentWillMount() {
    window.scroll(0, 0);
    if (window.localStorage.getItem("language") != null) {
      const getLang = window.localStorage.getItem("language") || "en";
      this.changeLang(getLang);
      this.setState({
        lang: getLang,
      });
    }
    this.getLanguage();

    //loader timeout
    // setTimeout(() => {
    //     console.log('timer', this.state.loading)
    //     this.setState({
    //         loading: false
    //     })
    // }, 3000);
  }

  fetchHomePageData = (lang) => {
    var networkHelper = new NetworkHelper();
    networkHelper.setUrl(Constants.get_home_page_data);
    networkHelper.setMethod("POST");
    networkHelper.setData(
      JSON.stringify({
        lang_code: lang,
      })
    );
    networkHelper.execute(
      (response) => {
        // console.log("Home Page Data >>>", response.data)
        this.setState({
          HomePageData: response.data,
          channel_section: response.data.channels_playlist,
          home_top_section: response.data.home_top_section,
          events_section: response.data.upcoming_events,
        });
      },
      function (errorMsg, StatusCode) {
        console.log(errorMsg, StatusCode);
      },
      function () {}
    );
  };

  handleImageClick = (url) => {
    if (typeof url !== "undefined") {
      window.location.href = url;
      return false;
    }
  };

  render() {
    const { HomePageData, channel_section, home_top_section, events_section } =
      this.state;
    return (
      <div>
        {/* loader-spinner */}
        {this.state.loading == true ? (
          <div className="loader-overlay">
            <img src={loaderImg} />
          </div>
        ) : null}

        <section className="main">
          <Container>
            {/* {home_top_section.ads != null ? (
							home_top_section.ads.ads_script != null && home_top_section.ads.ads_script.length ? (
								<Row className="txt-center ctm-row advertise">
									<Col sm={12} className="ctm-col">
										{home_top_section.ads.ads_script}
									</Col>
								</Row>
							) : (
								<Row className="txt-center ctm-row advertise">
									<Col sm={12} className="ctm-col">
										<figure>
											{" "}
											<a href={home_top_section.ads.ads_url}>
												<Image src={home_top_section.ads.ads_image} fluid />
											</a>
										</figure>
									</Col>
								</Row>
							)
						) : null} */}

            <Row className="banner-sec ctm-row">
              {/* SECTION1 */}

              <Col
                md={12}
                lg={6}
                className="bhadipa-banner ctm-col wow fadeIn"
                data-wow-offset="50"
                data-wow-delay="0.1s"
              >
                {home_top_section.section1 != null ? (
                  <figure className="bhadipa-banner-img cm-overlay  ctm-hid">
                    {/* <LazyLoad height={200}> */}
                    <Image
                      onLoad={this.imgLoader}
                      src={
                        this.state.lang === "en"
                          ? home_top_section.section1.banner.en
                          : home_top_section.section1.banner.ma
                      }
                      fluid
                      className="first-bnnr"
                      fallback={
                        <ImageTag
                          src={videoPlaceHolder}
                          width={550}
                          height={508}
                        />
                      }
                      style={{ cursor: "pointer" }}
                    />
                    {/* </LazyLoad> */}
                    <figcaption className="bs-card white">
                      <div className="tag-btn">
                        {home_top_section.section1.category != null
                          ? Object.keys(home_top_section.section1.category).map(
                              (id) => (
                                <Link
                                  to={`/tag/${home_top_section.section1.category[
                                    id
                                  ].toLowerCase()}`}
                                >
                                  {home_top_section.section1.category[id]}
                                </Link>
                              )
                            )
                          : null}
                      </div>
                      <div className="video-name">
                        <a href={`video/${home_top_section.section1.video.id}`}>
                          {home_top_section.section1.video.title}
                        </a>
                      </div>
                      <div className="channel-name">
                        <a
                          href={`channel/${home_top_section.section1.channel.id}`}
                        >
                          {home_top_section.section1.channel.title}
                        </a>
                      </div>
                      <a
                        className="mod-play-btn"
                        href={`video/${home_top_section.section1.video.id}`}
                      >
                        <i className="icon icon-play"></i>
                      </a>
                    </figcaption>
                  </figure>
                ) : (
                  ""
                )}
              </Col>

              <Col md={12} lg={6} className="ctm-col">
                <Row className="ctm-row">
                  {/* Section2 */}

                  <Col
                    md={12}
                    className="ctm-col wow fadeIn"
                    data-wow-delay="0.2s"
                  >
                    <Swiper data={home_top_section.section2} />
                  </Col>

                  {/* Section3 */}
                  <Col
                    md={12}
                    className="bhadipa-banner top-right-banner m-top-10 ctm-col wow fadeIn"
                  >
                    {home_top_section.section3 != null ? (
                      <figure className="bhadipa-banner-img cm-overlay  ctm-hid">
                        {/* <LazyLoad height={200}> */}
                        <Image
                          src={
                            this.state.lang === "en"
                              ? home_top_section.section3.banner.en
                              : home_top_section.section3.banner.ma
                          }
                          fluid
                          fallback={
                            <ImageTag
                              src={videoPlaceHolder}
                              width={550}
                              height={260}
                            />
                          }
                          style={{ cursor: "pointer" }}
                        />
                        {/* </LazyLoad> */}
                        <figcaption className="bs-card white">
                          <div className="tag-btn">
                            {home_top_section.section3.category != null
                              ? Object.keys(
                                  home_top_section.section3.category
                                ).map((id) => (
                                  <Link
                                    to={`/tag/${home_top_section.section3.category[
                                      id
                                    ].toLowerCase()}`}
                                  >
                                    {home_top_section.section3.category[id]}
                                  </Link>
                                ))
                              : null}
                          </div>
                          <div className="video-name">
                            <a
                              href={`video/${home_top_section.section3.video.id}`}
                            >
                              {home_top_section.section3.video.title}
                            </a>
                          </div>
                          <div className="channel-name">
                            <a
                              href={`channel/${home_top_section.section3.channel.id}`}
                            >
                              {home_top_section.section3.channel.title}
                            </a>
                          </div>
                          <a
                            className="mod-play-btn"
                            href={`video/${home_top_section.section3.video.id}`}
                          >
                            <i className="icon icon-play"></i>
                          </a>
                        </figcaption>
                      </figure>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="banner-sec banner-two ctm-row ">
              {/* Section4 */}
              <Col
                md={12}
                lg={4}
                className=" ctm-col wow fadeIn"
                data-wow-delay="0.3s"
              >
                {home_top_section.section4 != null ? (
                  <figure className="banner-card ctm-hid">
                    {/* <LazyLoad height={200}> */}
                    <Image
                      fallback={
                        <ImageTag
                          src={videoPlaceHolder}
                          width={550}
                          height={260}
                        />
                      }
                      onLoad={this.imgLoader}
                      src={
                        this.state.lang === "en"
                          ? home_top_section.section4.banner.en
                          : home_top_section.section4.banner.ma
                      }
                      fluid
                    />
                    {/* </LazyLoad> */}
                    <figcaption className="bs-card">
                      <div className="tag-btn">
                        {home_top_section.section4.category != null
                          ? Object.keys(home_top_section.section4.category).map(
                              (id) => (
                                <Link
                                  to={`/tag/${home_top_section.section4.category[
                                    id
                                  ].toLowerCase()}`}
                                >
                                  {home_top_section.section4.category[id]}
                                </Link>
                              )
                            )
                          : null}
                      </div>
                      <div className="video-name">
                        <a href={`video/${home_top_section.section4.video.id}`}>
                          {home_top_section.section4.video.title}
                        </a>
                      </div>
                      <div className="channel-name">
                        <a
                          href={`channel/${home_top_section.section4.channel.id}`}
                        >
                          {home_top_section.section4.channel.title}
                        </a>
                      </div>
                      <a
                        className="mod-play-btn"
                        href={`video/${home_top_section.section4.video.id}`}
                      >
                        <i className="icon icon-play"></i>
                      </a>
                    </figcaption>
                  </figure>
                ) : (
                  ""
                )}
              </Col>

              {/* Section 5 */}
              <Col
                md={12}
                lg={8}
                className="bhadipa-banner last ctm-col wow fadeIn"
                data-wow-delay="0.4s"
              >
                {home_top_section.section5 != null ? (
                  <figure className="bhadipa-banner-img cm-overlay  ctm-hid">
                    {/* <LazyLoad height={200}> */}
                    <Image
                      fallback={
                        <ImageTag
                          src={videoPlaceHolder}
                          width={737}
                          height={494}
                        />
                      }
                      onLoad={this.imgLoader}
                      src={
                        this.state.lang === "en"
                          ? home_top_section.section5.banner.en
                          : home_top_section.section5.banner.ma
                      }
                      fluid
                    />
                    {/* </LazyLoad> */}
                    <figcaption className="bs-card white">
                      <div className="tag-btn">
                        {home_top_section.section5.category != null
                          ? Object.keys(home_top_section.section5.category).map(
                              (id) => (
                                <Link
                                  to={`/tag/${home_top_section.section5.category[
                                    id
                                  ].toLowerCase()}`}
                                >
                                  {home_top_section.section5.category[id]}
                                </Link>
                              )
                            )
                          : null}
                      </div>
                      <div className="video-name">
                        <a href={`video/${home_top_section.section5.video.id}`}>
                          {home_top_section.section5.video.title}
                        </a>
                      </div>
                      <div className="channel-name">
                        <a
                          href={`channel/${home_top_section.section5.channel.id}`}
                        >
                          {home_top_section.section5.channel.title}
                        </a>
                      </div>
                      <a
                        className="mod-play-btn"
                        href={`video/${home_top_section.section5.video.id}`}
                      >
                        <i className="icon icon-play"></i>
                      </a>
                    </figcaption>
                  </figure>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Container>
        </section>

        {/* BHADIPA SERIES */}
        <SwiperComponent
          class="lyt-swiper theme"
          channelData={channel_section.section2}
          lang={this.state.lang}
          swip="right"
          type="1"
        />

        {/* BHA2PA SERIES */}
        <SwiperComponent
          class="lyt-swiper white"
          channelData={channel_section.section1}
          lang={this.state.lang}
          swip="left"
          type="2"
        />

        {/* Vishal Khole Series */}
        <SwiperComponent
          class="lyt-swiper base"
          channelData={channel_section.section3}
          lang={this.state.lang}
          swip="right"
          type="3"
        />

        {/* WEB SERIES */}
        <section className="mod-webseries2 yellow-line">
          <Container>
            <Row>
              <Col m={12}>
                <h2 className="title wow fadeIn">
                  {this.props.t("home.web", { framework: "web Series" })}
                </h2>
              </Col>
            </Row>
            <Row>
              <Swiperweb
                webseries={HomePageData.webseries}
                lang={this.state.lang}
              />
            </Row>
          </Container>
        </section>

        {/* EVENTS */}
        <section className="mod-event">
          <Event events={events_section} />
        </section>
        <section className="e-banner">
          <figure className="wow fadeIn">
            {/* <a href="https://bit.ly/31c1Ync" target="_blank" rel="noopener noreferrer">  */}
            <a
              href="https://bhadipa.merchgarage.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* <LazyLoad height={200}> */}
              {console.log(staticbanner, "staticBanner")}
              <ImageTag
                onLoad={this.imgLoader}
                src={staticbanner}
                fluid
                className="mob-pic"
              />

              {/* </LazyLoad> */}
            </a>
          </figure>
        </section>
        <section className="lyt-swiper bs-clnt-bx">
          <h2 className="title txt-center">Brands we worked with</h2>
          <Container fluid={false}>
            <Row>
            <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/google-logo.png")}
                  className="client-logo"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/morris-garages-logo.png")}
                  className="client-logo"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/vicco-logo.png")}
                  className="client-logo"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/british-high-council-logo.jpeg")}
                  className="client-logo"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/madhavbaug-logo.png")}
                  className="client-logo"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/scaler-logo.png")}
                  className="client-logo"
                />
              </Col>
              </Row>
            <Row>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/youva-logo.jpg")}
                  className="client-logo"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/net-protectr-logo.png")}
                  className="client-logo"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/brand-factory-logo.jpg")}
                  className="client-logo"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/eat-sure-logo.jpeg")}
                  className="client-logo"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/rambhandu-logo.jpeg")}
                  className="client-logo"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/moonshine-logo.jpeg")}
                  className="client-logo"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/ravetkar-logo.png")}
                  className="client-logo"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/toothsi-logo.png")}
                  className="client-logo"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/skinsi-logo.png")}
                  className="client-logo"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/sobha-developers-ltd-corporate-logo.png")}
                  className="client-logo"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/gs-tea-logo.png")}
                  className="client-logo"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/amazon.png")}
                  className="client-logo"
                />
              </Col>
              </Row>
              <Row>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/cambly.png")}
                  className="client-logo"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/cello.png")}
                  className="client-logo"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/cottonking.png")}
                  className="client-logo"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/deasra.png")}
                  className="client-logo"
                />
              </Col>
              
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/dunzo.png")}
                  className="client-logo"
                />
              </Col>
           
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/gilette.png")}
                  className="client-logo"
                />
              </Col>
              </Row>
            <Row>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/kesari.png")}
                  className="client-logo"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/legrand.png")}
                  className="client-logo"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/nayasa.png")}
                  className="client-logo"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/parachute.png")}
                  className="client-logo"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/pitambari.png")}
                  className="client-logo full-height"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/sleepy-owl.png")}
                  className="client-logo"
                />
              </Col>
              </Row>
              <Row>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/sony-sab.png")}
                  className="client-logo"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/storytel.png")}
                  className="client-logo"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/suhana.png")}
                  className="client-logo"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/tata-sampnn.png")}
                  className="client-logo"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/tinder.png")}
                  className="client-logo"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/tourism-australia.png")}
                  className="client-logo"
                />
              </Col>
              </Row>
            <Row className="last-row">
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/upGrad.png")}
                  className="client-logo"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/zapsubscribezoom.png")}
                  className="client-logo"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
              <ImageTag
                  src={require("../../images/clients/zee5.jpg")}
                  className="client-logo"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/bira-logo.jpeg")}
                  className="client-logo"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
                <ImageTag
                  src={require("../../images/clients/gargi-logo.jpg")}
                  className="client-logo"
                />
              </Col>
              <Col xs={6} sm={6} md={2} lg={2} className="logo-element">
              <ImageTag
                  src={require("../../images/clients/woloo-logo.png")}
                  className="client-logo"
                />
              </Col>
            </Row>
          </Container>
        </section>
        <section className="lyt-social">
          <Container fluid={true}>
            <Row>
              <Col md={12} lg={12} className="bg-base">
                <h2
                  className="title wow fadeInUp footer-text"
                  data-wow-delay="0.1s"
                >
                  {this.props.t("social.watch", { framework: "Watch us on" })}
                </h2>
                <a
                  className="fluid-icon wow fadeInUp"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.youtube.com/channel/UCUw8vQF-X7CJqdVpxBYcavQ"
                  data-wow-delay="0.2s"
                >
                  <figure>
                    {/* <LazyLoad height={200}> */}
                    <ImageTag onLoad={this.imgLoader} src={youtube} fluid />
                    {/* </LazyLoad> */}
                  </figure>
                </a>
                <a
                  className="fluid-icon wow fadeInUp"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/BhaDiPa/"
                  data-wow-delay="0.3s"
                >
                  <figure>
                    {/* <LazyLoad height={200}> */}
                    <ImageTag onLoad={this.imgLoader} src={facebook} fluid />
                    {/* </LazyLoad> */}
                  </figure>
                </a>
                <a
                  className="fluid-icon last wow fadeInUp"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/bhadipa/"
                  data-wow-delay="0.4s"
                >
                  <figure>
                    {/* <LazyLoad height={200}> */}
                    <ImageTag onLoad={this.imgLoader} src={instagram} fluid />
                    {/* </LazyLoad> */}
                  </figure>
                </a>
                <a
                  href="/contact"
                  className="cm-btn bg-shadow wow fadeInUp"
                  data-wow-delay="0.5s"
                >
                  {this.props.t("social.get", { framework: "Get In Touch" })}
                </a>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}

export default withTranslation("common")(Home);
