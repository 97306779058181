import React, { Component } from "react";
import { Link } from "react-router-dom";
import hashatg from "../../images/hashatg.png";
import NetworkHelper from "../../helpers/NetworkHelper";
import Constants from "../module/constants";
import { filterLanguage, SidenavImgClick, searchTerm } from "../module/Notify";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { showSearch } from "../module/Notify";
import {
  Container,
  Row,
  Col,
  Image,
  Nav,
  NavDropdown,
  Button,
  Dropdown,
  Form,
  FormControl,
} from "react-bootstrap";

class Sidenavbar extends Component {
  constructor() {
    super();
    this.state = {
      myclass: "",
      showSearch: false,
      hidesidebar: true,
      language: "en",
      NavBarData: null,
      channelData: null,
      webseriesData: null,
      countval: 0,
      isMobile: false,
      isOpened: false,
    };
    this.divclicked = this.divclicked.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (val) => {
    // console.log("language",val);
    this.setState({
      language: val,
    });
    filterLanguage(val);
    localStorage.setItem("language", val);
    this.fetchNavBarData(val);
    const { i18n } = this.props;
    i18n.changeLanguage(val);
  };
  handleSearchBox = () => {
    this.setState({
      showSearch: !this.state.showSearch,
    });
    showSearch(this.state.showSearch);
  };
  handleScroll = () => {
    if (this.state.countval < window.scrollY) {
      // console.log("true")
      if (this.state.isOpened == true) {
        this.setState({
          countval: window.scrollY,
        });
      } else {
        this.setState({
          hidesidebar: true,
          countval: window.scrollY,
          myclass: "",
        });
      }
    } else {
      // console.log("false")
      this.setState({
        hidesidebar: false,
        countval: window.scrollY,
      });
    }

    // if (window.scrollY > "130") {
    //   this.setState({
    //     hidesidebar: false
    //   });
    // }
    if (this.state.isMobile == false) {
      if (window.scrollY < "30") {
        this.setState({
          hidesidebar: true,
          myclass: "",
        });
      }
    }
  };

  changeLang = (val) => {
    const { i18n } = this.props;
    i18n.changeLanguage(val);
    this.fetchNavBarData(val);
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const url = this.props.location.pathname;
      const url_array = url.split("/");

      if (
        url_array[1] == "playlist" ||
        url_array[1] == "video" ||
        url_array[1] == "tag"
      ) {
        // console.log(url_array[1],"name")
        window.removeEventListener("scroll", this.handleScroll);
        this.setState({
          hidesidebar: false,
        });
      } else {
        window.addEventListener("scroll", this.handleScroll);
      }
    }
  }

  UNSAFE_componentWillMount() {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile == true) {
      this.setState({
        isMobile: isMobile,
        hidesidebar: false,
      });
    }
    // console.log(isMobile,"ismobile")
    const url = this.props.location.pathname;
    const url_array = url.split("/");

    if (
      url_array[1] == "playlist" ||
      url_array[1] == "video" ||
      url_array[1] == "tag"
    ) {
      this.setState({
        hidesidebar: false,
      });
    } else if (
      url_array[1] !== "playlist" &&
      url_array[1] !== "video" &&
      url_array[1] !== "tag"
    ) {
      window.addEventListener("scroll", this.handleScroll);
    }

    if (window.localStorage.getItem("language") !== null) {
      const getLang = window.localStorage.getItem("language") || "en";
      this.changeLang(getLang);
      this.setState({
        language: getLang,
      });
    }
    this.getLanguage();
  }

  fetchNavBarData = (lang) => {
    var networkHelper = new NetworkHelper();
    networkHelper.setUrl(Constants.get_navbar_links_data);
    networkHelper.setMethod("POST");
    networkHelper.setData(
      JSON.stringify({
        lang_code: lang,
      })
    );
    networkHelper.execute(
      (response) => {
        // console.log("Nav bar Page Data >>>", response.data.data)
        this.setState({
          channelData: response.data.data.channel,
          webseriesData: response.data.data.webseries,
        });
      },
      function (errorMsg) {
        console.log(errorMsg);
      },
      function () {}
    );
  };

  // Event emiter for language changed
  getLanguage() {
    window.addEventListener("filterLanguage", (e) => {
      const { i18n } = this.props;
      this.setState({
        lang: e.detail,
      });
      i18n.changeLanguage(e.detail);
      this.fetchNavBarData(e.detail);
    });
  }

  divclicked() {
    if (this.state.myclass === "") {
      this.setState({
        myclass: "coolclass",
        isOpened: true,
      });
    } else {
      this.setState({
        myclass: "",
        isOpened: false,
      });
    }
  }

  logo_clicked() {
    // console.log("logo clicked");
    this.setState({
      hidesidebar: true,
    });
    SidenavImgClick(false);
  }

  render() {
    const { channelData, webseriesData, hidesidebar } = this.state;
    // console.log("name", hidesidebar)
    return (
      <Container
        fluid={true}
        className={hidesidebar == true ? "bar-sticky nav-hide" : "bar-sticky"}
      >
        <Row className="sidebar-nav">
          <Col lg={5} sm={12}>
            <div id="box">
              <Row className="ctm-row">
                <Col xs={1} className="ctm-col" onClick={this.divclicked}>
                  <i className="icon icon-hamburger"></i>
                </Col>
                <Col xs={9} className="ctm-col logo ctm-show">
                  <Link to={"/"}>
                    <figure>
                      <Image
                        src={hashatg}
                        onClick={() => this.logo_clicked()}
                        fluid
                      />
                    </figure>
                  </Link>
                </Col>
                <Col xs={2} className="ctm-col ctm-show language-box">
                  {/* <a href="#">
                    <i className="icon icon-search"></i>
                  </a> */}
                  <Button
                    variant="link"
                    onClick={() => {
                      this.props.history.push("/search");
                    }}
                    className="icon-search sticky-search"
                  ></Button>
                  <div className="language-box sticky-bx">
                    <Nav.Item>
                      <Nav.Link
                        className={
                          this.state.language === "en"
                            ? "active"
                            : "white-link1"
                        }
                        onClick={() => this.handleChange("en")}
                        eventKey="link-1"
                      >
                        Eng
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        className={
                          this.state.language === "ma"
                            ? "active abc"
                            : "white-link abc"
                        }
                        onClick={() => this.handleChange("ma")}
                        eventKey="link-2"
                      >
                        मराठी
                      </Nav.Link>
                    </Nav.Item>
                  </div>
                </Col>
              </Row>
            </div>
            <div id="seconddiv" className={this.state.myclass}>
              <a onClick={this.divclicked} className="ctm-block txt-right">
                <i className="icon icon-Shape"></i>
              </a>

              <Nav className="mr-auto">
                {/* {
                  (channelData !== null) ?
                    Object.keys(channelData).map((id) => (
                      <NavDropdown title={channelData[id].title} id="basic-nav-dropdown">
                        {
                          Object.keys(channelData[id].subcategory).map((sub_id) => (
                            <NavDropdown.Item  onClick={this.divclicked} as={Link} to={`/playlist/${channelData[id].subcategory[sub_id].id}`}>
                              {channelData[id].subcategory[sub_id].name}
                            </NavDropdown.Item>
                          ))
                        }
                      </NavDropdown>
                    ))
                    : null
                } */}
                {channelData !== null
                  ? Object.keys(channelData).map((id, index) => (
                      <div key={index} className="dropdown nav-item">
                        <Link
                          className="nav-link"
                          onClick={this.divclicked}
                          to={`/channel/${channelData[id].id}`}
                        >
                          {channelData[id].title}
                        </Link>
                        <Dropdown>
                          <Dropdown.Toggle variant=""></Dropdown.Toggle>

                          <Dropdown.Menu>
                            {Object.keys(channelData[id].subcategory).reverse().map(
                              (sub_id, index2) => (
                                <NavDropdown.Item
                                  key={index2}
                                  onClick={this.divclicked}
                                  as={Link}
                                  to={`/playlist/${channelData[id].subcategory[sub_id].id}`}
                                >
                                  {channelData[id].subcategory[sub_id].name}
                                </NavDropdown.Item>
                              )
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      // <NavDropdown title={channelData[id].title} id="basic-nav-dropdown">
                      //   {
                      //     Object.keys(channelData[id].subcategory).map((sub_id) => (
                      //       <NavDropdown.Item  onClick={this.divclicked} as={Link} to={`/playlist/${channelData[id].subcategory[sub_id].id}`}>
                      //         {channelData[id].subcategory[sub_id].name}
                      //       </NavDropdown.Item>
                      //     ))
                      //   }
                      // </NavDropdown>
                    ))
                  : null}
                <div className="dropdown nav-item">
                  <Link
                    className="nav-link"
                    onClick={this.divclicked}
                    to={`/webseries`}
                  >
                    {this.props.t("navbar.webseries", {
                      framework: "Web Series",
                    })}
                  </Link>
                  <Dropdown>
                    <Dropdown.Toggle variant=""></Dropdown.Toggle>

                    <Dropdown.Menu>
                      {webseriesData !== null
                        ? webseriesData.map((nav, index) => (
                            <NavDropdown.Item
                              key={index}
                              onClick={this.divclicked}
                              target="_blank"
                              href={nav.external_link}
                            >
                              {nav.title}
                            </NavDropdown.Item>
                          ))
                        : null}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                {/* <NavDropdown
									title={this.props.t("navbar.webseries", {
										framework: "Web Series",
									})}
									id="basic-nav-dropdown"
								>
									{webseriesData !== null
										? webseriesData.map((nav) => (
												<NavDropdown.Item onClick={this.divclicked} target="_blank" href={nav.external_link}>
													{nav.title}
												</NavDropdown.Item>
										  ))
										: null}
								</NavDropdown> */}
                <Nav.Link
                  href="https://bhadipa.merchgarage.com/"
                  target="_blank"
                >
                  {this.props.t("navbar.shop", { framework: "Shop Now" })}
                </Nav.Link>
                <div className="dropdown nav-item">
                  <Link
                    className="nav-link"
                    onClick={this.divclicked}
                    to={`/events`}
                  >
                    {this.props.t("navbar.events", { framework: "Events" })}
                  </Link>
                  <Dropdown>
                    <Dropdown.Toggle variant=""></Dropdown.Toggle>

                    <Dropdown.Menu>
                      <NavDropdown.Item
                        onClick={this.divclicked}
                        as={Link}
                        to="/events"
                      >
                        {this.props.t("navbar.event.link1", {
                          framework: "Events",
                        })}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={this.divclicked}
                        as={Link}
                        to="/eventformats"
                      >
                        {this.props.t("navbar.event.link2", {
                          framework: "Event Formats",
                        })}
                      </NavDropdown.Item>
                      {/* <NavDropdown.Item onClick={this.divclicked} as={Link} to="/comedians">
												{this.props.t("navbar.event.link3", {
													framework: "comedians",
												})}
											</NavDropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                {/*
								 ///////////////// 
								 <div className="dropdown nav-item">
									<Link className="nav-link" onClick={this.divclicked} to={`/events`}>
										{/* {this.props.t("navbar.events", { framework: "Comedian" })} */}
                {/* Comedian
									</Link>
									<a>
									<Dropdown>
										<Dropdown.Toggle variant=""></Dropdown.Toggle>

										<Dropdown.Menu>
										<a>
										<NavDropdown.Item onClick={this.divclicked} as={Link} to="/events"> */}
                {/* {this.props.t("navbar.event.link1", {
													framework: "Comedian",
												})} */}
                {/* Creators & Actors (instead of influencers)
											</NavDropdown.Item>
										</a>
											<a>
											<NavDropdown.Item onClick={this.divclicked} as={Link} to="/eventformats"> */}
                {/* {this.props.t("navbar.event.link2", {
													framework: "Comedians",
												})} */}
                {/* Comedians
											</NavDropdown.Item>
											</a>
											<a>
											<NavDropdown.Item onClick={this.divclicked} as={Link} to="/comedians"> */}
                {/* {this.props.t("navbar.event.link3", {
													framework: "",
												})} */}
                {/* Writers & Directors
											</NavDropdown.Item>
											</a>
										
										</Dropdown.Menu>
									</Dropdown>
									</a>
								</div> 
								/////////////////
								*/}
                {/* <NavDropdown title={this.props.t("navbar.events", { framework: "Events" })} id="basic-nav-dropdown">
									
								</NavDropdown> */}
                {/* <Nav.Link onClick={this.divclicked} as={Link} to="/talent">
                  {this.state.language === "en" ? "Talent" : "प्रतिभा"}
                </Nav.Link> */}
                <Nav.Link onClick={this.divclicked} as={Link} to="/about">
                  {this.props.t("navbar.About", { framework: "About" })}
                </Nav.Link>
                <Nav.Item className="ctm-show">
                  <Nav.Link onClick={this.divclicked} as={Link} to="/contact">
                    {this.props.t("navbar.touch", {
                      framework: "Get in Touch",
                    })}
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item className="ctm-show">
                  <Nav.Link as={Link} to="/login">
                    {this.props.t("navbar.signup", { framework: "Sign Up" })}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="ctm-show">
                  <Nav.Link as={Link} to="/login">
                    {this.props.t("navbar.login", { framework: "Login" })}
                  </Nav.Link>
                </Nav.Item> */}
              </Nav>
            </div>
          </Col>
          <Col lg={2} className="logo ctm-hide">
            <Link to={"/"}>
              <figure>
                <Image
                  src={hashatg}
                  onClick={() => this.logo_clicked()}
                  fluid
                />
              </figure>
            </Link>
          </Col>
          <Col lg={5} className="links ctm-hide">
            <Nav>
              <i
                onClick={() => {
                  this.handleSearchBox();
                }}
                className="icon icon-search stick-search"
              ></i>

              <Form
                inline
                className="search-box"
                onSubmit={() => {
                  this.props.history.push("/search");
                }}
              >
                {/* <div className="d-flex">
                  <FormControl
                    type="text"
                    placeholder="Find videos....."
                    className="mr-sm-2 bar-sticky-search"
                    onChange={(e) => {
                      searchTerm(e.target.value);
                      if (e.target.value !== "") {
                        window.localStorage.setItem("search", e.target.value);
                      }
                      this.setState({
                        search_term: e.target.value,
                      });
                    }}
                  />
                </div> */}
              </Form>
              <Nav.Item>
                <Nav.Link as={Link} to="/contact">
                  Get in touch
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link as={Link} to="/media">
                  Sign up
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/channel" className="txt-yellow">
                  Login
                </Nav.Link>
              </Nav.Item> */}

              <div className="language-box sticky-bx">
                <Nav.Item>
                  <Nav.Link
                    className={
                      this.state.language === "en" ? "active" : "white-link1"
                    }
                    onClick={() => this.handleChange("en")}
                    eventKey="link-1"
                  >
                    Eng
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className={
                      this.state.language === "ma"
                        ? "active abc"
                        : "white-link abc"
                    }
                    onClick={() => this.handleChange("ma")}
                    eventKey="link-2"
                  >
                    मराठी
                  </Nav.Link>
                </Nav.Item>
              </div>
            </Nav>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default withTranslation("common")(withRouter(Sidenavbar));
