import React, { useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import Swiper from "react-id-swiper";

import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
var swip = "";
var data = [];
var lang = "en";
var nav = {};
const SwiperComponent = (props) => {
	let key = props.type;
	if (key === "1") {
		nav = {
			nextEl: ".theme .channel-desc .ctm-right",
			prevEl: ".theme .channel-desc .ctm-left",
		};
	} else if (key === "2") {
		nav = {
			nextEl: ".white .channel-desc .ctm-right",
			prevEl: ".white .channel-desc .ctm-left",
		};
	} else if (key === "3") {
		nav = {
			nextEl: ".base .channel-desc .ctm-right",
			prevEl: ".base .channel-desc .ctm-left",
		};
	}
	const params = {
		slidesPerView: 3,
		spaceBetween: 20,
		navigation: nav,
		// autoplay: {
		//   delay: 3000,
		//   disableOnInteraction: false
		// },
		breakpoints: {
			1024: {
				slidesPerView: 3,
				spaceBetween: 20,
			},
			768: {
				slidesPerView: 2,
				spaceBetween: 20,
			},
			640: {
				slidesPerView: 2,
				spaceBetween: 20,
				navigation: nav,
			},
			320: {
				slidesPerView: 1,
				spaceBetween: 10,
				navigation: nav,
			},
		},
	};
	data = props.data;
	swip = props.swip;
	lang = props.lang;

	useEffect(() => {});
	const renderLink = (ids) => {
		if (data !== null && data !== "") {
			switch (props.type) {
				case "1": {
					return `/channel/${ids}`;
				}
				case "2": {
					return `/playlist/${ids}`;
				}
				case "3": {
					return `/video/${ids}`;
				}
			}
		}
	};
	const renderName = () => {
		switch (props.type) {
			case "1": {
				return <h2>{props.t("search.channel", { framework: "Channels" })}</h2>;
				break;
			}
			case "2": {
				return <h2>{props.t("search.playlist", { framework: "Playlists" })}</h2>;
			}
			case "3": {
				return <h2>{props.t("search.video", { framework: "Videos" })}</h2>;
			}
		}
	};
	return (
		<section className={props.class}>
			<Container>
				{data != null ? (
					<Row>
						<Col md={swip == "left" ? { span: 4, order: 12 } : 3} className="channel-desc wow fadeInUp" data-wow-delay="0.1s">
							{renderName()}
							<div className="ctm-left"></div>
							<div className="ctm-right"></div>
						</Col>
						<Col md={swip == "left" ? { span: 8, order: 1 } : 9} className="wow fadeInUp" data-wow-delay="0.2s">
							<Swiper {...params} shouldSwiperUpdate>
								{
									// console.log("Channel Data",data)
									Object.keys(data).map((id) => (
										// console.log(data[id].banner.ma),
										<div>
											<Link to={renderLink(data[id].id)} className="ctm-block">
												<figure className="series-pic">
													<LazyLoad height={200}>
														<Image src={lang === "en" ? data[id].banner.en : data[id].banner.ma} fluid height="100%" />
													</LazyLoad>
													<figcaption className="video-name">{data[id].title}</figcaption>
												</figure>
											</Link>
										</div>
									))
								}
							</Swiper>
						</Col>
					</Row>
				) : null}
			</Container>
		</section>
	);
};

export default withTranslation("common")(SwiperComponent);
