import React from "react";
import "./App.scss";
import Navbar from "./components/layouts/Navbar";
import Home from "./components/layouts/Home";
import About from "./components/layouts/About";
import Media from "./components/layouts/Media";
import Contact from "./components/layouts/Contact";
import Login from "./components/layouts/Login";
import Footer from "./components/layouts/Footer";
import Channel from "./components/layouts/Channel";
import Series from "./components/layouts/Series";
import Events from "./components/layouts/Events";
import Eventformats from "./components/layouts/Events-format";
import Comedians from "./components/layouts/Comedians";
import Talent from "./components/layouts/Talent";
import Terms from "./components/layouts/Terms";
import Privacy from "./components/layouts/Privacy";
import Links from "./components/layouts/Links";
import Sidenavbar from "./components/layouts/Sidenavbar";
import Search from "./components/layouts/Search";
import SearchBox from "./components/layouts/SearchBox";
import Webseries from "./components/layouts/Webseries";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearch: false,
    };
  }
  UNSAFE_componentWillMount() {
    window.addEventListener("showSearch", (e) => {
      this.setState({
        showSearch: e.detail,
      });
    });
  }
  render() {
    return (
      // <Router>
      <Router basename={"/"}>
        {/* clients/bhadipa-html/ */}
        <div>
          {this.state.showSearch == false ? (
            <div>
              <Navbar />
              <Sidenavbar />
            </div>
          ) : (
            ""
          )}

          {this.state.showSearch == true ? (
            <SearchBox />
          ) : (
            <Switch>
              <Route exact path="/contact">
                <Contact />
              </Route>
              <Route exact path="/login">
                <Login />
              </Route>
              <Route exact path="/comedians">
                <Comedians />
              </Route>
              <Route exact path="/media">
                <Media />
              </Route>
              <Route exact path="/channel/:id">
                <Channel />
              </Route>
              <Route exact path="/webseries">
                <Webseries />
              </Route>
              <Route exact path="/events">
                <Events />
              </Route>
              <Route exact path="/Eventformats">
                <Eventformats />
              </Route>
              <Route exact path="/about">
                <About />
              </Route>
              <Route exact path="/talent">
                <Talent />
              </Route>
              <Route exact path="/terms">
                <Terms />
              </Route>
              <Route exact path="/privacy">
                <Privacy />
              </Route>
              <Route exact path="/links">
                <Links />
              </Route>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/playlist/:id">
                <Series />
              </Route>
              <Route exact path="/video/:id">
                <Series />
              </Route>
              <Route exact path="/tag/:id">
                <Series />
              </Route>
              <Route exact path="/search">
                <Search />
              </Route>
            </Switch>
          )}

          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;
