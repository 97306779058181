import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";
import videoPlaceHolder from "../../images/video-placeholder.png";
import { Shimmer } from 'react-shimmer'
const convertToSlug = (string) => {
	return string
		.toLowerCase()
		.replace(/ /g, "-")
		.replace(/[^\w-]+/g, "");
};
const SwiperComedian = (props) => {
	let comedians = props.comedians;
	let lang = props.lang;
// import React from 'react';
// import { Container, Row, Col, Image } from 'react-bootstrap';
// import { withTranslation, Trans } from 'react-i18next';


// const SwiperComedian = props => {
//     let comedians = props.comedians;
//     let lang = props.lang;
//   console.log("lang...........", lang)
//     return (
//         <Row>
//             {
//                 (comedians != null) ?
//                     // console.log("Data hold",comedians)
//                     Object.keys(comedians).reverse().map((id) => (
//                         // console.log("Dev",id)

//                         <Col lg={4} sm={6} className="wow fadeInUp" data-wow-delay="0.1s">
//                             <div className="bs-box Comedians">

//                                 <figure> <Image src={comedians[id].image } fluid className="pic" /></figure>
//                                 <h4 className="head">{comedians[id].name}</h4>
//                                 <p>{comedians[id].description}</p>
//                                 <div className="links">
//                                     {
//                                         (comedians[id].video_link != null) ? <a href={comedians[id].video_link} target="_blank" className="cm-btn">
//                                             {props.t('comedians.video', { framework: "View video" })}
//                                         </a>
//                                             : null
//                                     }

//                                     {
//                                         (comedians[id].twitter_link != null) ? <a href={comedians[id].twitter_link} target="_blank" className="social-icon"><i className="icon icon-twitter"></i></a>
//                                             : null
//                                     }
//                                     {
//                                         (comedians[id].instagram_link != null) ? <a href={comedians[id].instagram_link} target="_blank" className="social-icon">
//                                             <i className="icon icon-instagram"></i>
//                                         </a> : null
//                                     }
//                                     {
//                                         (comedians[id].fb_link != null) ? <a href={comedians[id].fb_link} target="_blank" className="social-icon">
//                                             <i className="icon icon-facebook"></i>
//                                         </a> : null
//                                     }
//                                     {
//                                         (comedians[id].video_link != null) ? <a href={comedians[id].video_link} target="_blank" className="social-icon">
//                                             <i className="icon icon-youtube"><span className="path1"></span><span className="path2"></span></i>
//                                         </a> : null
//                                     }



//                                 </div>
//                             </div>
//                         </Col>

	return (
		<Row>
			{comedians != null ? (
				Object.keys(comedians).map((id) => (
					<Col lg={4} sm={6} className="wow fadeInUp" data-wow-delay="0.1s">
						<a href={`#${convertToSlug(comedians[id].name)}`}>
							<div className="bs-box Comedians" id={`${convertToSlug(comedians[id].name)}`}>
								<figure>
									<LazyLoad height={200}>
										<Image fallback={<Image src={videoPlaceHolder} fluid className="pic" />} NativeImgProps={{ className: "img-fluid pic" }} src={lang == "en" ? comedians[id].image.en : comedians[id].image.ma} />
									</LazyLoad>
								</figure>
								<h4 className="head">{comedians[id].name}</h4>
								<p>{comedians[id].description}</p>
								<div className="links">
									{comedians[id].video_link != null ? (
										<a href={comedians[id].video_link} target="_blank" rel="noopener noreferrer" className="cm-btn">
											{props.t("comedians.video", { framework: "View video" })}
										</a>
									) : null}

									{comedians[id].twitter_link != null ? (
										<a href={comedians[id].twitter_link} target="_blank" rel="noopener noreferrer" className="social-icon">
											<i className="icon icon-twitter"></i>
										</a>
									) : null}
									{comedians[id].instagram_link != null ? (
										<a href={comedians[id].instagram_link} target="_blank" rel="noopener noreferrer" className="social-icon">
											<i className="icon icon-instagram"></i>
										</a>
									) : null}
									{comedians[id].fb_link != null ? (
										<a href={comedians[id].fb_link} target="_blank" rel="noopener noreferrer" className="social-icon">
											<i className="icon icon-facebook"></i>
										</a>
									) : null}
									{comedians[id].yt_link != null ? (
										<a href={comedians[id].yt_link} target="_blank" rel="noopener noreferrer" className="social-icon">
											<i className="icon icon-youtube">
												<span className="path1"></span>
												<span className="path2"></span>
											</i>
										</a>
									) : null}
								</div>
							</div>
						</a>
					</Col>
				))
			) : (
				<div></div>
			)}
		</Row>
	);
};

export default withTranslation("common")(SwiperComedian);
