import React, { Component } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import Constants from "../module/constants";
import NetworkHelper from "../../helpers/NetworkHelper";
class Comic extends Component {
	constructor() {
		super();
		this.state = {
			fields: {},
			errors: {},
		};
		this.handleChange = this.handleChange.bind(this);
		this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
	}

	submitForm = () => {
		console.log("Submit");
		// e.preventDefault();
		var networkHelper = new NetworkHelper();
		networkHelper.setUrl(Constants.collab);
		networkHelper.setMethod("POST");
		networkHelper.setData(
			JSON.stringify({
				name: this.state.fields.name,
				email: this.state.fields.email,
				age: this.state.fields.age,
				link: this.state.fields.link,
				yourself: this.state.fields.yourself,
			})
		);
		console.log("state Data >>>", this.name);
		networkHelper.execute(
			(response) => {
				console.log("response Data >>>", response.data);
				this.setState({});
			},
			function (errorMsg, StatusCode) {
				console.log(errorMsg);
			},
			function () {}
		);
	};

	UNSAFE_componentWillMount() {
		window.scrollTo(0, 0);
		// this.submitForm();
	}

	handleChange(e) {
		let fields = this.state.fields;
		fields[e.target.name] = e.target.value;
		this.setState({
			fields,
		});
	}

	submituserRegistrationForm(e) {
		e.preventDefault();
		if (this.validateForm()) {
			let fields = {};
			fields["name"] = "";
			fields["email"] = "";
			fields["age"] = "";
			fields["link"] = "";
			fields["yourself"] = "";
			this.setState({ fields: fields });
			this.submitForm();
			alert("Form submitted");
		}
	}

	validateForm() {
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;

		if (!fields["name"]) {
			formIsValid = false;
			errors["name"] = "false";
		}

		if (typeof fields["name"] !== "undefined") {
			//Full name
			if (!fields["name"].match(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/)) {
				formIsValid = false;
				errors["name"] = "false";
			}
		}

		if (!fields["email"]) {
			formIsValid = false;
			errors["email"] = "false";
		}

		if (typeof fields["email"] !== "undefined") {
			//regular expression for email validation
			var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
			if (!pattern.test(fields["email"])) {
				formIsValid = false;
				errors["email"] = "false";
			}
		}

		if (!fields["age"]) {
			formIsValid = false;
			errors["age"] = "false";
		}

		if (typeof fields["age"] !== "undefined") {
			const age = parseInt(fields["age"], 10);
			if (isNaN(age) || age < 1 || age > 120) {
				formIsValid = false;
				errors["age"] = "false";
			}
		}

		if (!fields["link"]) {
			formIsValid = false;
			errors["link"] = "false";
		}

		if (typeof fields["link"] !== "undefined") {
			if (!fields["link"].match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/)) {
				formIsValid = false;
				errors["link"] = "false";
			}
		}

		if (!fields["yourself"]) {
			formIsValid = false;
			errors["yourself"] = "false";
		}

		if (typeof fields["yourself"] !== "undefined") {
			//not null
			if (!fields["yourself"].match(/^$|\s*/)) {
				formIsValid = false;
				errors["yourself"] = "false";
			}
		}

		this.setState({
			errors: errors,
		});
		return formIsValid;
	}

	render() {
		// console.log("Statwe", this.state.fields.name)
		return (
			<div className="bs-form typ-contact-forms">
				<Form onSubmit={this.submituserRegistrationForm}>
					<Row>
						<Col md={12} className="m-b-40">
							<Form.Control className="inpt-p-top-0" type="text" placeholder="Name" name="name" value={this.state.fields.name} onChange={this.handleChange} isValid={this.state.errors.name === "true"} isInvalid={this.state.errors.name === "false"} autocom />
							{this.state.errors.name === "false" ? <label>Enter the name</label> : ""}
						</Col>
						<Col md={12} className="m-b-40">
							<Form.Control type="email" placeholder="Email Id" name="email" value={this.state.fields.email} onChange={this.handleChange} isValid={this.state.errors.email === "true"} isInvalid={this.state.errors.email === "false"} />
							{this.state.errors.email === "false" ? <label>Enter valid email</label> : ""}
						</Col>
						<Col md={12} className="m-b-40">
							<Form.Control type="text" min="1" placeholder="Age" name="age" value={this.state.fields.age} onChange={this.handleChange} isValid={this.state.errors.age === "true"} isInvalid={this.state.errors.age === "false"} />
							{this.state.errors.age === "false" ? <label>Enter valid age</label> : ""}
						</Col>
						<Col md={12}></Col>
						<Col md={12} className="m-b-40">
							<Form.Control placeholder="Share the link" name="link" value={this.state.fields.link} onChange={this.handleChange} isValid={this.state.errors.link === "true"} isInvalid={this.state.errors.link === "false"} />
							{this.state.errors.link === "false" ? <label>Enter valid link</label> : ""}
						</Col>
						<Col md={12}>
							<Form.Control as="textarea" rows="1" placeholder="Tell us about yourself" className="last" value={this.state.fields.yourself} name="yourself" onChange={this.handleChange} isValid={this.state.errors.yourself === "true"} isInvalid={this.state.errors.yourself === "false"} />
							{this.state.errors.yourself === "false" ? <label>Let us know more about you! Write a brief description.</label> : ""}
						</Col>
						<Button type="submit" className="ctm-btn">
							<i class="icon icon-plane"></i>
						</Button>
					</Row>
				</Form>
			</div>
		);
	}
}
export default Comic;
