import React, { Component } from "react";
import youtube from "../../images/youtube.png";
import facebook from "../../images/facebook.png";
import mxplayer from "../../images/mx-player.png";
import Collaborate from "./../form/Collaborate";
import Advertise from "./../form/Advertise";
import Careers from "./../form/Careers";
import Feedback from "./../form/Feedback-form";
import Comic from "../form/Comic";
import { Container, Row, Col, Image, Tab, Nav } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";
import Instagram from "../../common/Instagram";
class Contact extends Component {
	constructor(props) {
		super(props);
		this.state = { tab: "collaborate", lang: "en" };
		this.handleChange = this.handleChange.bind(this);
	}

	//Change the lang using constructor
	changeLang = (val) => {
		const { i18n } = this.props;
		i18n.changeLanguage(val);
	};

	handleChange = (val) => {
		this.setState({
			tab: val,
		});
	};
	UNSAFE_componentWillMount() {
		window.scrollTo(0, 0);
		if (window.localStorage.getItem("language") != null) {
			const getLang = window.localStorage.getItem("language") || "en";
			this.changeLang(getLang);
			this.setState({
				lang: getLang,
			});
		}
		if (window.localStorage.getItem("tab") != null && window.localStorage.getItem("tab") !== "undefined") {
			const tab = window.localStorage.getItem("tab") || "collaborate";
			console.log("Tab", tab);
			// this.handleChange(tab)
			this.setState({
				tab: tab,
			});
		}
		this.getTab();
		this.getLanguage();
	}

	// Event emiter for language changed
	getLanguage() {
		window.addEventListener("filterLanguage", (e) => {
			const { i18n } = this.props;
			this.setState({
				lang: e.detail,
			});
			i18n.changeLanguage(e.detail);
		});
	}

	getTab() {
		window.addEventListener("changeTabFilter", (e) => {
			if (e.detail != null) this.handleChange(e.detail);
		});
	}

	render() {
		return (
			<div>
				<section className="lyt-white">
					<Container className="contact">
						<Row className="bs-tab typ-form-tab">
							<Col lg={12}>
								<Tab.Container id="left-tabs-example">
									<div className="tab-options">
										<h2 className="title wow fadeInUp">{this.props.t("contact.get", { framework: "Get In Touch" })}</h2>
										{/* <Nav className="tab-list wow fadeInUp" data-wow-delay="0.1s" >
											<Nav.Item>
												<Nav.Link eventKey="first" className={this.state.tab === "collaborate" ? "active" : ""} onClick={() => this.handleChange("collaborate")}>
													{this.props.t("contact.tab1", { framework: "Collaborate" })}
												</Nav.Link>
											</Nav.Item>

											<Nav.Item>
												<Nav.Link eventKey="second" className={this.state.tab === "advertise" ? "active" : ""} onClick={() => this.handleChange("advertise")}>
													{this.props.t("contact.tab2", { framework: "Advertise" })}
												</Nav.Link>
											</Nav.Item>

											<Nav.Item>
												<Nav.Link eventKey="third" className={this.state.tab === "careers" ? "active" : ""} onClick={() => this.handleChange("careers")}>
													{this.props.t("contact.tab3", { framework: "careers" })}
												</Nav.Link>
											</Nav.Item>

											<Nav.Item>
												<Nav.Link eventKey="four" className={this.state.tab === "feedback" ? "active" : ""} onClick={() => this.handleChange("feedback")}>
													{this.props.t("contact.tab4", { framework: "feedback" })}
												</Nav.Link>
											</Nav.Item>

											<Nav.Item>
												<Nav.Link eventKey="five" className={this.state.tab === "comic" ? "active" : ""} onClick={() => this.handleChange("comic")}>
													{this.state.lang == "en" ? ("GOT TALENT?") : ("प्रतिभा मिळाली?")}
												</Nav.Link>
											</Nav.Item>
										</Nav> */}
									</div>

									<Tab.Content className="wow fadeInUp" data-wow-delay="0.2s">
										<Tab.Pane active={this.state.tab === "collaborate" ? true : false} eventKey="first">
											<Collaborate changeTab={this.handleSelect} />
										</Tab.Pane>
										<Tab.Pane active={this.state.tab === "advertise" ? true : false} eventKey="second">
											<Advertise changeTab={this.handleSelect} />
										</Tab.Pane>
										<Tab.Pane active={this.state.tab === "careers" ? true : false} eventKey="third">
											<Careers changeTab={this.handleSelect} />
										</Tab.Pane>
										<Tab.Pane active={this.state.tab === "feedback" ? true : false} eventKey="four">
											<Feedback changeTab={this.handleSelect} />
										</Tab.Pane>
										<Tab.Pane active={this.state.tab === "comic" ? true : false} eventKey="five">
											<Comic changeTab={this.handleSelect} />
										</Tab.Pane>
									</Tab.Content>
								</Tab.Container>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="lyt-social">
					<Container fluid={true}>
						<Row>
							<Col md={12} lg={12} className="bg-base">
								<h2 className="title wow fadeInUp footer-text" data-wow-delay="0.1s">
									{this.props.t("social.watch", { framework: "Watch us on" })}
								</h2>
								<a className="fluid-icon wow fadeInUp" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCUw8vQF-X7CJqdVpxBYcavQ" data-wow-delay="0.2s">
									<figure>
										<LazyLoad height={200}>
											<Image src={youtube} fluid />
										</LazyLoad>
									</figure>
								</a>
								<a className="fluid-icon wow fadeInUp" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/BhaDiPa/" data-wow-delay="0.3s">
									<figure>
										<LazyLoad height={200}>
											<Image src={facebook} fluid />
										</LazyLoad>
									</figure>
								</a>
								<Instagram />
								<a href="/contact" className="cm-btn bg-shadow wow fadeInUp" data-wow-delay="0.5s">
									{this.props.t("social.get", { framework: "Get In Touch" })}
								</a>
							</Col>
						</Row>
					</Container>
				</section>
			</div>
		);
	}
}

export default withTranslation("common")(Contact);
