import React from "react";
import youtube from "../../images/youtube.png";
import facebook from "../../images/facebook.png";
import mxplayer from "../../images/mx-player.png";
import Event from "./../module/Event";
import Eventswiper from "./../module/Swiper-event";
import { Container, Row, Col, Image } from "react-bootstrap";
import NetworkHelper from "../../helpers/NetworkHelper";
import Constants from "../module/constants";
import { withTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";
import { HashLink } from "react-router-hash-link";
import videoPlaceHolder from "../../images/video-placeholder.png";
import {Image as ShimmerImage } from "react-shimmer";
import Instagram from "../../common/Instagram";

class Events extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			upcoming_events: [],
			events_format: [],
			lang: "en",
			loading: false,
			count: 0,
		};
	}

	//Change the lang using constructor
	changeLang = (val) => {
		const { i18n } = this.props;
		i18n.changeLanguage(val);
		this.getEventsPageData(val);
	};

	UNSAFE_componentWillMount() {
		if (window.localStorage.getItem("language") != null) {
			const getLang = window.localStorage.getItem("language") || "en";
			this.changeLang(getLang);
			this.setState({
				lang: getLang,
			});
		}
		this.getLanguage();
	}

	getEventsPageData(lang) {
		var networkHelper = new NetworkHelper();
		networkHelper.setUrl(Constants.get_events_format_page_data);
		networkHelper.setMethod("POST");
		networkHelper.setData(
			JSON.stringify({
				lang_code: lang,
			})
		);
		networkHelper.execute(
			(response) => {
				this.setState({
					banner_images: response.data.data.banner,
					upcoming_events: response.data.data.upcoming_events,
					events_format: response.data.data.events_format.events,
				});
			},
			function (errorMsg) {
				console.log(errorMsg);
			},
			function () {}
		);
	}

	//Loader function
	imgLoader = () => {
		if (this.state.count >= 2) {
			this.setState({
				loading: false,
			});
		} else {
			this.setState({
				count: this.state.count + 1, //counts 'onLoad' in Img tags and update
			});
		}
	};

	getLanguage() {
		window.addEventListener("filterLanguage", (e) => {
			this.setState({
				lang: e.detail,
			});
			const { i18n } = this.props;
			i18n.changeLanguage(e.detail);
			this.getEventsPageData(e.detail);
		});
	}
	convertToSlug = (string) => {
		return string
			.toLowerCase()
			.replace(/ /g, "-")
			.replace(/[^\w-]+/g, "");
	};
	render() {
		window.scroll(0, 0);
		const { upcoming_events, events_format, banner_images } = this.state;
		return (
			<div>
				<section className="bs-swiper">
					<Eventswiper lang={this.state.lang} events={banner_images} />
				</section>

				<section className="mod-event">
					<Event lang={this.state.lang} events={upcoming_events} />
				</section>

				<section className="lyt-white">
					<Container>
						<Row>
							<Col lg={12}>
								<h2 className="title wow fadeInUp">{this.props.t("events.event_format", { framework: "event format" })}</h2>
							</Col>
						</Row>
						<Row>
							{events_format != null
								? Object.keys(events_format).map((id) => (
										<Col lg={3} sm={6} className="bs-box wow fadeInUp" data-wow-delay="0.1s">
											<HashLink to={`eventformats#${this.convertToSlug(events_format[id].title)}`} className="ctm-block">
												<figure>
													<div className="ctm-hid">
														<LazyLoad height={200}>
															<ShimmerImage fallback={<Image src={videoPlaceHolder} fluid className="pic" />} NativeImgProps={{ className: "img-fluid pic" }} src={events_format[id].banner.en} />
														</LazyLoad>
													</div>
													<figcaption className="head base">{events_format[id].title}</figcaption>
												</figure>
											</HashLink>
										</Col>
								  ))
								: null}
						</Row>
					</Container>
				</section>

				<section className="lyt-social">
					<Container fluid={true}>
						<Row>
							<Col md={12} lg={12} className="bg-base">
								<h2 className="title wow fadeInUp footer-text" data-wow-delay="0.1s">
									{this.props.t("social.watch", { framework: "Watch us on" })}
								</h2>
								<a className="fluid-icon wow fadeInUp" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCUw8vQF-X7CJqdVpxBYcavQ" data-wow-delay="0.2s">
									<figure>
										<LazyLoad height={200}>
											<Image src={youtube} fluid />
										</LazyLoad>
									</figure>
								</a>
								<a className="fluid-icon wow fadeInUp" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/BhaDiPa/" data-wow-delay="0.3s">
									<figure>
										<LazyLoad height={200}>
											<Image src={facebook} fluid />
										</LazyLoad>
									</figure>
								</a>
								<Instagram />
								<a href="/contact" className="cm-btn bg-shadow wow fadeInUp" data-wow-delay="0.5s">
									{this.props.t("social.get", { framework: "Get In Touch" })}
								</a>
							</Col>
						</Row>
					</Container>
				</section>
			</div>
		);
	}
}

export default withTranslation("common")(Events);
