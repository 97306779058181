import React, { Component } from "react";
import YTSearch from "youtube-api-search";
import VideoList from "./Youtube-player/video_list";
import VideoDetail from "./Youtube-player/video_detail";
import { Scrollbars } from "react-custom-scrollbars";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const API_KEY = "AIzaSyBdVut9QCzqAHBzfDEh30yUp4E529som6s";

class VideoPlayer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			videos: props.videos,
			selectedVideo: props.videos[0],
			autoplay: props.autoplay,
		};

		// console.log("props",props.videos)
		// this.videoSearch('bhadipa');
	}

	UNSAFE_componentWillMount() {
		window.addEventListener("changeVideoId", (e) => {
			this.setState({
				selectedVideo: this.props.videos[e.detail],
			});
		});

		window.addEventListener("playVideo", (e) => {
			this.setState({
				autoplay: this.state.selectedVideo,
			});
		});
	}
	videoSearch(searchTerm) {
		YTSearch({ key: API_KEY, term: searchTerm }, (data) => {
			// console.log(data);
			this.setState({
				videos: data,
				selectedVideo: data[0],
			});
		});
	}
	render() {
		return (
			<Row className="video-player">
				{/* <SearchBar onSearchTermChange={searchTerm => this.videoSearch(searchTerm)}/> */}
				<Col lg={12} className="no-padding">
					<VideoDetail autoplay={this.state.autoplay != null ? this.state.autoplay : null} video={this.state.selectedVideo} />
					{/* </Col>
        <Col lg={2} xs={12} md={4} className="no-padding"> */}
					<div className="arrow-container">
						<Link style={{ color: "#FFFFFF" }} onClick={this.props.closeBanner}>
							<i className="icon icon-right-arrow-big"></i> Back 
						</Link>
					</div>
					<Scrollbars className="video-list">
						<VideoList onVideoSelect={(userSelected) => this.setState({ selectedVideo: userSelected })} videos={this.state.videos} />
					</Scrollbars>
				</Col>
			</Row>
		);
	}
}

export default VideoPlayer;
